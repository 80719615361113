import React, { useEffect, useState } from 'react'
import { defer, delay } from 'lodash'

import Button from 'components/Button'
import FieldWrapper from 'components/FieldWrapper'
import Flex from 'components/Flex'
import Input from 'components/Input'
import Link from 'next/link'
import Select from 'components/Select'
import Spacer from 'components/Spacer'
import Text from 'components/Text'
import styled from 'styled-components'
import theme from 'styles/theme'

type Props = {
  onSuccess: () => void
  submitText?: string
  formType?: 'free-trial-request' | 'callback-request'
}

const LeadCaptureForm = (props: Props) => {
  const { onSuccess, submitText = 'Submit', formType = 'free-trial-request' } = props

  const companySizes = ['1-5', '6-10', '11-20', '21-49', '50+']

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [email, setEmail] = useState('')
  const [contactNumber, setContactNumber] = useState('')
  const [companySize, setCompanySize] = useState(companySizes[0])

  const [submitAttempts, setSubmitAttempts] = useState(0)
  const [allowSubmit, setAllowSubmit] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    delay(() => setAllowSubmit(true), 2000)
  }, [])

  const errors = {
    firstName: submitAttempts && firstName.length === 0 ? 'Enter your first name' : undefined,
    lastName: submitAttempts && lastName.length === 0 ? 'Enter your last name' : undefined,
    companyName: submitAttempts && companyName.length === 0 ? 'Enter your company' : undefined,
    email: submitAttempts && email.length === 0 ? 'Enter your work email' : undefined,
  }

  const onSubmit = async () => {
    setSubmitAttempts(submitAttempts + 1)

    if (!firstName || !lastName || !companyName || !email) return

    if (!allowSubmit) return

    setSubmitting(true)

    await fetch('/api/lead', {
      method: 'POST',
      body: JSON.stringify({
        formType,
        firstName,
        lastName,
        companyName,
        email,
        contactNumber,
        companySize,
        context: {
          pageName: document.title,
        },
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })

    setSubmitting(false)

    await onSuccess()

    setSubmitAttempts(0)
    setFirstName('')
    setLastName('')
    setCompanyName('')
    setEmail('')
    setContactNumber('')
  }

  return (
    <Container>
      <Flex column gap={16}>
        <NameFields>
          <FieldWrapper label='First Name*' error={errors.firstName}>
            <Input
              name='firstName'
              autoComplete='given-name'
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder='First Name'
              $error={!!errors.firstName}
              disabled={submitting}
            />
          </FieldWrapper>
          <FieldWrapper label='Last Name*' error={errors.lastName}>
            <Input
              name='lastName'
              autoComplete='family-name'
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder='Last Name'
              $error={!!errors.lastName}
              disabled={submitting}
            />
          </FieldWrapper>
        </NameFields>

        <FieldWrapper label='Company Name*' error={errors.companyName}>
          <Input
            name='companyName'
            autoComplete='organization'
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            placeholder='Company Name'
            $error={!!errors.companyName}
            disabled={submitting}
          />
        </FieldWrapper>

        <FieldWrapper label='Work Email*' error={errors.email}>
          <Input
            name='email'
            autoComplete='off'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder='name@company.com'
            $error={!!errors.email}
            disabled={submitting}
          />
        </FieldWrapper>

        <FieldWrapper label='Contact Number (optional)'>
          <Input
            name='contactNumber'
            autoComplete='off'
            value={contactNumber}
            onChange={(e) => setContactNumber(e.target.value)}
            placeholder='+44 7890 654321'
            disabled={submitting}
          />
        </FieldWrapper>

        <FieldWrapper label='Company Size (optional)'>
          <Select
            options={companySizes.map(($companySize) => ({ label: $companySize, value: $companySize }))}
            value={companySize}
            onChange={setCompanySize}
          />
        </FieldWrapper>
      </Flex>

      <Spacer size={{ default: 24, md: 24 }} />

      <Button arrow block loading={submitting} onClick={() => defer(() => onSubmit())}>
        {submitText}
      </Button>

      <Spacer size={{ default: 16, md: 24 }} />

      <Disclaimer>
        <Text type='h6' align='center'>
          Onetrace needs the contact information you provide to us to contact you about our products and services. You
          may unsubscribe from these communications at any time. For more information, please review our{' '}
          <Link href='/legal/privacy-policy'>Privacy Policy</Link>.
        </Text>
      </Disclaimer>
    </Container>
  )
}

export default LeadCaptureForm

const Container = styled.div``

const NameFields = styled.div`
  width: 100%;
  gap: 16px;
  display: flex;
  flex-direction: column;
  @media ${theme.breakpoints.atleast.md} {
    flex-direction: row;
  }
`

const Disclaimer = styled.div`
  margin: auto;
  max-width: 240px;
  @media ${theme.breakpoints.atleast.sm} {
    max-width: 345px;
  }
`
