import React from 'react'
import styled from 'styled-components'
import theme from 'styles/theme'

const Outer = styled.div<{ $fillHeight?: boolean }>`
  margin: auto;
  height: ${({ $fillHeight }) => ($fillHeight ? '100%' : 'auto')};
`

const Inner = styled.div<{ $fillHeight?: boolean }>`
  margin: auto;
  max-width: 1280px;
  height: ${({ $fillHeight }) => ($fillHeight ? '100%' : 'auto')};
  padding: 0px 24px;
  display: flex;
  flex-direction: column;

  @media ${theme.breakpoints.atleast.md} {
    padding: 0px 80px;
    max-width: 1440px;
  }
`

type Props = {
  children?: React.ReactNode
  fillHeight?: boolean
}

const Centraliser: React.FC<Props> = (props) => {
  const { children, fillHeight } = props

  return (
    <Outer $fillHeight={fillHeight}>
      <Inner $fillHeight={fillHeight}>{children}</Inner>
    </Outer>
  )
}

export default Centraliser
