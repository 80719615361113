import React, { useEffect } from 'react'

import BaseModal from 'react-modal'
import styled from 'styled-components'
import theme from 'styles/theme'

type Props = {
  onClose: () => void
  children?: React.ReactNode
}

export default function Modal(props: Props) {
  const { onClose, children } = props

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore-next-line
    document.querySelector('body').style.overflow = 'hidden'
    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore-next-line
      document.querySelector('body').style.overflow = 'scroll'
    }
  }, [])

  return (
    <BaseModal
      isOpen
      style={{
        overlay: {
          backgroundColor: theme.colors.charcoal40,
          zIndex: 5000,
          padding: 0,
        },
        content: {
          inset: 0,
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          background: 'none',
          border: 'none',
          borderRadius: 0,
        },
      }}
    >
      <ModalWrapper>
        <ModalBody>
          <CloseButton onClick={() => onClose()}>
            <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.29245 5.00004L0.0498051 0.7574L0.756912 0.050293L4.99955 4.29293L9.24219 0.050293L9.9493 0.7574L5.70666 5.00004L9.9493 9.24268L9.24219 9.94979L4.99955 5.70715L0.756911 9.94979L0.0498047 9.24268L4.29245 5.00004Z'
                fill={theme.colors.charcoal}
              />
            </svg>
          </CloseButton>
          {children}
        </ModalBody>
      </ModalWrapper>
    </BaseModal>
  )
}

const ModalWrapper = styled.div`
  width: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${theme.colors.white};

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  @media ${theme.breakpoints.atleast.md} {
    position: relative;
    width: auto;
    height: auto;
    overflow: visible;
    padding: 68px;
    background-color: transparent;
  }

  @media ${theme.breakpoints.atleast.lg} {
    padding: 48px;
  }
`

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  width: 100%;
  padding: 24px;
  padding-top: 56px;

  @media ${theme.breakpoints.atleast.md} {
    max-width: 560px;
    padding: 64px;
    box-shadow: 0px 25px 45px ${theme.colors.charcoal20};
    border: 1px solid ${theme.colors.charcoal10};
    border-radius: 32px;
    max-width: 560px;
  }
`

const CloseButton = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.charcoal10};
  border-radius: 8px;
  cursor: pointer;
  transition: all 300ms ease;

  &:hover {
    background: ${theme.colors.charcoal20};
  }

  @media ${theme.breakpoints.atleast.md} {
    top: 32px;
    right: 32px;
  }
`
