import Centraliser from 'components/Centraliser'
import { Mode } from 'styles/types'
import React from 'react'
import styled from 'styled-components'
import theme from 'styles/theme'
import useWindowScrollDistance from 'helpers/useWindowScrollDistance'

type Props = {
  mode?: Mode
  children: React.ReactNode
  showDesktopNav: boolean
}

export default function Bar(props: Props) {
  const { mode = 'dark', children } = props

  const minScrollDistance = 24

  const isScrolling = useWindowScrollDistance(minScrollDistance) >= minScrollDistance

  const className = isScrolling ? 'scrolling' : undefined

  return (
    <Container className={className} $mode={mode}>
      <Panel className={className} $mode={mode}>
        <Centraliser fillHeight>
          <InnerWrapper>{children}</InnerWrapper>
        </Centraliser>
      </Panel>
    </Container>
  )
}

const Container = styled.div<{ $mode: Mode }>`
  background-color: ${({ $mode }) => ($mode === 'light' ? theme.colors.white : theme.colors.navy)};
  height: 56px;

  @media ${theme.breakpoints.atleast.md} {
    height: 64px;
  }

  @media ${theme.breakpoints.atleast.lg} {
    height: 86px;
    transition: height 0.25s ease;

    &.scrolling {
      height: 64px;
    }
  }
`

const Panel = styled.div<{ $mode: Mode }>`
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 56px;
  background-color: ${({ $mode }) => ($mode === 'light' ? theme.colors.white : theme.colors.navy)};
  border-color: ${({ $mode }) => ($mode === 'light' ? theme.colors.charcoal10 : theme.colors.white10)};
  border-bottom-style: solid;
  border-bottom-width: 1px;

  transition: background-color 0.25s ease, height 0.25s ease, padding 0.25s ease, border 0.25s ease;

  @media ${theme.breakpoints.atleast.lg} {
    backdrop-filter: blur(7px);
    background-color: ${({ $mode }) => ($mode === 'light' ? theme.colors.white90 : theme.colors.navy90)};
  }

  @media ${theme.breakpoints.atleast.md} {
    height: 64px;
  }

  @media ${theme.breakpoints.atleast.lg} {
    height: 86px;
    padding-top: 46px;
    border-width: 0px;

    &.scrolling {
      height: 64px;
      padding: 12px 0px;
      border-width: 1px;
    }
  }
`

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`
