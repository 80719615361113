import React from 'react'
import ReactMarkdown from 'react-markdown'
import type { TextColor } from 'styles/types'
import styled from 'styled-components'
import theme from 'styles/theme'

type TextType =
  | 'body-large'
  | 'body-large-pullout'
  | 'body-small'
  | 'body-small-pullout'
  | 'mega'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'

type TextAlign = 'left' | 'right' | 'center' | 'justify'

type Props = {
  wrapper?: 'div' | 'span' | 'p'
  type?: TextType
  color?: TextColor
  withMarkdown?: boolean
  children: React.ReactNode
  align?: TextAlign
}

const Text = (props: Props) => {
  const { wrapper, type = 'body-large', color, withMarkdown = false, children, align, ...restProps } = props

  return (
    <TextWrapper {...restProps} as={wrapper} $type={type} $color={color} $align={align}>
      {withMarkdown && <ReactMarkdown>{children as string}</ReactMarkdown>}
      {!withMarkdown && children}
    </TextWrapper>
  )
}

export default Text

type TextWrapperProps = {
  $type: TextType
  $color?: TextColor
  $align?: TextAlign
}

const TextWrapper = styled.div<TextWrapperProps>`
  ${({ $type }) => theme.typography[$type].mobile}
  @media ${theme.breakpoints.atleast.md} {
    ${({ $type }) => theme.typography[$type].desktop}
  }

  ${({ $color }) => $color && `color: ${theme.colors[$color]} !important;`}
  text-align: ${({ $align }) => $align};
`
