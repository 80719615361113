import styled, { css } from 'styled-components'

import theme from 'styles/theme'

const Input = styled.input<{ $error?: boolean }>`
  box-sizing: border-box;
  outline: none;
  border: 1px solid ${theme.colors.charcoal40};
  border-radius: 8px;
  line-height: 20px;
  color: ${theme.colors.charcoal};
  width: 100%;

  transition: border 300ms ease;

  &::placeholder {
    color: ${theme.colors.charcoal60};
  }

  &:hover {
    border: 1px solid ${theme.colors.charcoal};
  }

  &:focus {
    border: 1px solid ${theme.colors.blue};
    box-shadow: 0px 0px 4px rgba(0, 94, 235, 0.5);
  }

  ${({ $error }) =>
    $error &&
    css`
      &,
      &:hover {
        border: 1px solid ${theme.colors.errorLight};
      }
      &:focus {
        border: 1px solid ${theme.colors.errorLight};
        box-shadow: 0px 0px 4px rgba(230, 0, 4, 0.5);
      }
    `}

  ${theme.typography.h5.mobile}
  height: 40px;
  padding: 0px 10px;

  @media ${theme.breakpoints.atleast.md} {
    ${theme.typography.h5.desktop}
    height: 48px;
    padding: 0px 14px;
  }
`

export default Input
