import Script from 'next/script'
import useConfig from 'helpers/useConfig'
import useCookieSettings from 'helpers/useCookieSettings'

type ConversionType = 'free-trial-request' | 'demo-request' | 'callback-request'

type Props = {
  type: ConversionType
}

export default function GoogleAdsConversion(props: Props) {
  const { type } = props

  const config = useConfig()

  const cookieSettings = useCookieSettings()

  const conversionIds = {
    'free-trial-request': config.googleAdsFreeTrialRequestConversionId,
    'demo-request': config.googleAdsDemoRequestConversionId,
    'callback-request': config.googleAdsCallbackRequestConversionId,
  }

  const conversionId = conversionIds[type]

  if (!type || !config.googleAdsId || !conversionId || !cookieSettings.marketing || !cookieSettings.analytics) {
    return null
  }

  return (
    <Script id='gtag-conversion' strategy='afterInteractive'>
      {`
        const googleAdsId = "${config.googleAdsId}";
        const conversionId = "${conversionId}";
        gtag('event', 'conversion', {'send_to': googleAdsId+'/'+conversionId});
      `}
    </Script>
  )
}
