import BaseImage from 'next/image'
import { Mode } from 'styles/types'
import React from 'react'
import logoDark from 'public/assets/logo-dark.svg'
import logoWhite from 'public/assets/logo-white.svg'
import styled from 'styled-components'

type Props = {
  mode: Mode
  onClick?: () => void
}

const Logo: React.FC<Props> = (props) => {
  const { mode, onClick } = props

  const src = {
    light: logoWhite,
    dark: logoDark,
  }

  return (
    <Image src={src[mode]} alt='Onetrace' onClick={onClick} />
  )
}

export default Logo

const Image = styled(BaseImage)`
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  cursor: ${({ onClick }) => onClick ? 'pointer' : 'inherit'};
  transition: opacity 0.25s ease;
  @media (hover: none) {
    &:active {
      opacity: 0.5;
    }
  }
`