import { isObject } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import theme from 'styles/theme'
import type { SpacerSizeConfig, SpacerSize } from 'styles/types'

type SpacerElementProps = {
  $default: SpacerSize
  $sm?: SpacerSize
  $md?: SpacerSize
  $lg?: SpacerSize
  $xl?: SpacerSize
  $xxl?: SpacerSize
}

const SpacerElement = styled.div<SpacerElementProps>`
  height: ${({ $default }) => $default}px;
  @media ${theme.breakpoints.atleast.sm} {
    height: ${({ $default, $sm }) => $sm || $default}px;
  }
  @media ${theme.breakpoints.atleast.md} {
    height: ${({ $default, $sm, $md }) => $md || $sm || $default}px;
  }
  @media ${theme.breakpoints.atleast.lg} {
    height: ${({ $default, $sm, $md, $lg }) => $lg || $md || $sm || $default}px;
  }
  @media ${theme.breakpoints.atleast.xl} {
    height: ${({ $default, $sm, $md, $lg, $xl }) => $xl || $lg || $md || $sm || $default}px;
  }
  @media ${theme.breakpoints.atleast.xxl} {
    height: ${({ $default, $sm, $md, $lg, $xl, $xxl }) => $xxl || $xl || $lg || $md || $sm || $default}px;
  }
`

type Props = {
  size?: SpacerSizeConfig
}

const Spacer: React.FC<Props> = (props) => {
  const { size = { default: 24, lg: 32 } } = props

  const fallback = 24

  const $default = isObject(size) ? size.default || fallback : size || fallback
  const $sm = isObject(size) ? size.sm : undefined
  const $md = isObject(size) ? size.md : undefined
  const $lg = isObject(size) ? size.lg : undefined
  const $xl = isObject(size) ? size.xl : undefined
  const $xxl = isObject(size) ? size.xxl : undefined

  return <SpacerElement $default={$default} $sm={$sm} $md={$md} $lg={$lg} $xl={$xl} $xxl={$xxl} />
}

export default Spacer
