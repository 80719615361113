import React, { useEffect, useState } from 'react'

import CookieNotice from 'layout/cookies/CookieNotice'
import FacebookPixel from './scripts/FacebookPixel'
import Footer from './footer'
import GoogleTag from 'layout/scripts/GoogleTag'
import Header from './header'
import Hotjar from './scripts/Hotjar'
import Hubspot from './scripts/Hubspot'
import Intercom from './scripts/Intercom'
import LeadCaptureModal from 'layout/modals/LeadCaptureModal'
import LinkedinInsightTag from './scripts/LinkedinInsightTag'
import ManageCookiesModal from 'layout/modals/ManageCookiesModal'
import { Mode } from 'styles/types'
import ThanksModal from './modals/ThanksModal'
import WhatConverts from './scripts/WhatConverts'
import theme from 'styles/theme'
import useConfig from 'helpers/useConfig'
import { useGlobalContexts } from 'layout/GlobalContextsProvider'

type Props = {
  initialHeaderMode?: Mode
  children: React.ReactNode
}

export type CookieSettings = {
  functional: boolean
  analytics: boolean
  marketing: boolean
}

const Layout = (props: Props) => {
  const { initialHeaderMode = 'light', children } = props

  const { showLeadCaptureModal, setShowLeadCaptureModal } = useGlobalContexts()
  const { showThanksModal, setShowThanksModal } = useGlobalContexts()
  const { showManageCookiesModal, setShowManageCookiesModal } = useGlobalContexts()
  const { showCookieNotice, setShowCookieNotice } = useGlobalContexts()

  const [cookieSettings, setCookieSettings] = useState<CookieSettings | null>(null)

  useEffect(() => {
    const $cookieSettings = localStorage.getItem('cookieSettings')
    if ($cookieSettings) {
      setCookieSettings(JSON.parse($cookieSettings))
      setShowCookieNotice(false)
    } else {
      setShowCookieNotice(true)
    }
  }, [setShowCookieNotice])

  const updateCookieSettings = ($cookieSettings: CookieSettings) => {
    localStorage.setItem('cookieSettings', JSON.stringify($cookieSettings))
    setCookieSettings($cookieSettings)
    setShowCookieNotice(false)
    setShowManageCookiesModal(false)
  }

  const acceptAllCookies = () => {
    updateCookieSettings({
      functional: true,
      analytics: true,
      marketing: true,
    })
  }

  const config = useConfig()

  const [headerMode, setHeaderMode] = useState<Mode>(initialHeaderMode)

  function determineHeaderMode() {
    const header = document.querySelectorAll<HTMLElement>('header')[0]
    const body = document.querySelectorAll<HTMLElement>('body')[0]
    const sections = document.querySelectorAll<HTMLElement>('section.block')

    sections.forEach((section) => {
      if (
        window.pageYOffset >= section.offsetTop - header.offsetHeight / 2 &&
        window.pageYOffset < section.offsetTop + section.offsetHeight
      ) {
        const shouldBeDarkMode = section.className
          .split(' ')
          .filter((className) => className.startsWith('block-scheme'))
          .join('')
          .includes('dark')

        setHeaderMode(shouldBeDarkMode ? 'dark' : 'light')
        body.style.backgroundColor = theme.colors[shouldBeDarkMode ? 'navy' : 'white']
        return
      }
    })
  }

  useEffect(() => {
    setTimeout(() => determineHeaderMode(), 100)

    window.addEventListener('scroll', determineHeaderMode)
    return () => {
      window.removeEventListener('scroll', determineHeaderMode)
    }
  }, [])

  return (
    <>
      {cookieSettings && cookieSettings.analytics && <Hotjar siteId={config.hotjarSiteId} />}
      {cookieSettings && cookieSettings.analytics && (
        <GoogleTag
          analyticsMeasurementId={config.googleAnalyticsMeasurementId}
          analyticsTrackingId={config.googleAnalyticsTrackingId}
        />
      )}
      {cookieSettings && cookieSettings.analytics && cookieSettings.marketing && (
        <GoogleTag adsId={config.googleAdsId} />
      )}
      {cookieSettings && cookieSettings.analytics && cookieSettings.marketing && (
        <WhatConverts profileId={config.whatConvertsProfileId} />
      )}

      <header>
        <Header mode={headerMode} />
      </header>

      <main>{children}</main>

      <footer>
        <Footer />
      </footer>

      {showLeadCaptureModal && <LeadCaptureModal onClose={() => setShowLeadCaptureModal(false)} />}

      {showThanksModal && <ThanksModal onClose={() => setShowThanksModal(false)} />}

      {showManageCookiesModal && (
        <ManageCookiesModal
          cookieSettings={cookieSettings}
          onClose={() => setShowManageCookiesModal(false)}
          onSave={($cookieSettings) => updateCookieSettings($cookieSettings)}
        />
      )}

      {showCookieNotice && <CookieNotice onAccept={() => acceptAllCookies()} />}

      {!showCookieNotice && <Intercom appId={config.intercomAppId} />}

      {cookieSettings && cookieSettings.marketing && <FacebookPixel pixelId={config.facebookPixelId} />}
      {cookieSettings && cookieSettings.marketing && <LinkedinInsightTag partnerId={config.linkedinPartnerId} />}
      {cookieSettings && cookieSettings.marketing && <Hubspot hubspotId={config.hubspotId} />}
    </>
  )
}

export default Layout
