import Button from 'components/Button'
import GoogleAdsConversion from 'layout/scripts/GoogleAdsConversion'
import Heading from 'components/Heading'
import Illustration from 'components/Illustration'
import Modal from 'layout/modals/Modal'
import React from 'react'
import Spacer from 'components/Spacer'
import Text from 'components/Text'
import styled from 'styled-components'
import theme from 'styles/theme'

type Props = {
  onClose: () => void
}

export default function ThanksModal(props: Props) {
  const { onClose } = props

  return (
    <Modal onClose={() => onClose()}>
      <Wrapper>
        <Illustration name='132x70:success' />

        <Spacer size={{ default: 8, md: 16 }} />

        <Heading type='h3'>Thank you!</Heading>

        <Spacer size={{ default: 16, md: 24 }} />

        <Text type='body-small' align='center'>
          A member of our team will be in touch shortly!
        </Text>

        <Spacer size={{ default: 40 }} />

        <Button block onClick={() => onClose()}>
          Finish
        </Button>

        <GoogleAdsConversion type='callback-request' />
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 280px;
  margin: auto;
  @media ${theme.breakpoints.atleast.md} {
    max-width: 100%;
  }
`
