import Button from 'components/Button'
import Icon from 'components/Icon'
import { Mode } from 'styles/types'
import React from 'react'
import styled from 'styled-components'
import theme from 'styles/theme'
import { useGlobalContexts } from 'layout/GlobalContextsProvider'

type Props = {
  mode: Mode
  showMobileNav: boolean
  expandMobileNav: boolean
  onToggleMobileNav: () => void
}

export default function AccountNav(props: Props) {
  const { mode, showMobileNav, expandMobileNav, onToggleMobileNav } = props

  const { setShowLeadCaptureModal } = useGlobalContexts()

  return (
    <Container>
      <LoginLink $mode={mode} href='https://onetrace.cloud'>
        Log In
      </LoginLink>

      <Button color='blue' size='small' onClick={() => setShowLeadCaptureModal(true)}>
        Try for Free
      </Button>

      {showMobileNav && (
        <BurgerMenu onClick={() => onToggleMobileNav()}>
          <Icon scheme={mode === 'dark' ? 'dark-blue' : 'white'} icon={expandMobileNav ? 'xMark' : 'burger'} />
        </BurgerMenu>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  gap: 12px;
  @media ${theme.breakpoints.atleast.md} {
    gap: 16px;
  }
`

const LoginLink = styled.a<{ $mode?: Mode }>`
  ${theme.typography.h5.mobile}
  @media ${theme.breakpoints.atleast.md} {
    ${theme.typography.h5.desktop}
  }
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  color: ${({ $mode = 'light' }) => ($mode == 'light' ? theme.colors.charcoal : theme.colors.white)};
  &:hover {
    color: ${({ $mode = 'light' }) => ($mode == 'light' ? theme.colors.charcoal60 : theme.colors.white60)};
  }
`

const BurgerMenu = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: opacity 0.25s ease;
  @media (hover: none) {
    &:active {
      opacity: 0.5;
    }
  }
`
