import React, { useEffect, useState } from 'react'

import AccountNav from './components/AccountNav'
import Bar from './components/Bar'
import DesktopNav from './components/DesktopNav'
import Logo from 'components/Logo'
import MobileNav from './components/MobileNav'
import { Mode } from 'styles/types'
import styled from 'styled-components'
import theme from 'styles/theme'
import { useGlobalContexts } from 'layout/GlobalContextsProvider'

type Props = {
  mode?: Mode
}

export default function Header(props: Props) {
  const { mode = 'light' } = props

  const [isLarge, setIsLarge] = useState(false)

  const showDesktopNav = isLarge
  const showMobileNav = !isLarge

  const [expandMobileNav, setExpandMobileNav] = React.useState(false)

  const { headerMenu } = useGlobalContexts()

  const determineScreenSize = () => setIsLarge(window.innerWidth >= theme.breakpointNumbers.lg)

  useEffect(() => {
    setTimeout(() => determineScreenSize(), 100)
    window.addEventListener('resize', determineScreenSize)
    return () => {
      window.removeEventListener('resize', determineScreenSize)
    }
  }, [])

  return (
    <Bar mode={mode} showDesktopNav={showDesktopNav}>
      <LogoPositioner>
        <Logo
          mode={mode}
          onClick={() => {
            window.location.href = '/'
          }}
        />
      </LogoPositioner>

      {showDesktopNav && <DesktopNav mode={mode} headerMenu={headerMenu} />}

      {showMobileNav && expandMobileNav && (
        <MobileNav headerMenu={headerMenu} closeMobileNav={() => setExpandMobileNav(false)} />
      )}

      <AccountNav
        mode={mode}
        showMobileNav={showMobileNav}
        expandMobileNav={expandMobileNav}
        onToggleMobileNav={() => setExpandMobileNav(!expandMobileNav)}
      />
    </Bar>
  )
}

const LogoPositioner = styled.div`
  display: flex;
  img {
    height: 26px !important;
    width: 118px !important;
    @media ${theme.breakpoints.atleast.md} {
      height: 34px !important;
      width: 154px !important;
    }
  }
`
