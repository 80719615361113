import React from 'react'
import styled from 'styled-components'
import theme from 'styles/theme'

const TagElement = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 2px 8px;
  white-space: nowrap;

  ${theme.typography.h6.mobile}
  @media ${theme.breakpoints.atleast.lg} {
    ${theme.typography.h6.desktop}
  }

  &.orange {
    background: ${theme.colors.chocolate20};
    color: ${theme.colors.chocolate} !important;
  }
`

type Props = {
  text: string
  type?: string
}

const Tag: React.FC<Props> = (props) => {
  const { text, type = 'orange' } = props

  return <TagElement className={type}>{text}</TagElement>
}

export default Tag
