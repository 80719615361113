import type { ColorScheme, SpacerSizeConfig } from 'styles/types'

import React from 'react'
import Spacer from './Spacer'
import styled from 'styled-components'

const Section = styled.section``

type Props = {
  children?: React.ReactNode
  scheme?: ColorScheme
  spacerTop?: SpacerSizeConfig
  spacerBottom?: SpacerSizeConfig
  style?: React.CSSProperties
}

const Block: React.FC<Props> = (props) => {
  const { children, scheme = 'white', spacerTop, spacerBottom} = props

  const className = `block block-scheme-${scheme}`

  return (
    <Section className={className}>
      {!!spacerTop && <Spacer size={spacerTop} />}

      {children}

      {!!spacerBottom && <Spacer size={spacerBottom} />}
    </Section>
  )
}

export default Block
