import React from 'react'
import styled from 'styled-components'
import theme from 'styles/theme'

type Option = {
  label: string
  value: string
}

type Props = {
  options?: Option[]
  value?: string
  onChange?: (value: string) => void
  mode?: 'light' | 'dark'
}

const Select = (props: Props) => {
  const { options = [], value, onChange, mode = 'light' } = props

  const selectedOption = options.find((option) => option.value === value)

  return (
    <Container $mode={mode}>
      <SelectedValue>{selectedOption?.label}</SelectedValue>
      <Arrow>
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M5 7L8 10L11 7'
            stroke={mode === 'light' ? theme.colors.charcoal : theme.colors.white}
            strokeLinecap='square'
            strokeLinejoin='round'
          />
        </svg>
      </Arrow>
      <SelectElement onChange={(event) => onChange?.(event.target.value)} value={value}>
        {options.map((option: Option, i: number) => (
          <option key={i} value={option.value}>
            {option.label}
          </option>
        ))}
      </SelectElement>
    </Container>
  )
}

export default Select

const Container = styled.div<{ $mode: 'light' | 'dark' }>`
  box-sizing: border-box;
  outline: none;
  border: 1px solid ${({ $mode }) => ($mode === 'light' ? theme.colors.charcoal40 : theme.colors.white60)};
  border-radius: 8px;
  line-height: 20px;
  color: ${({ $mode }) => ($mode === 'light' ? theme.colors.charcoal : theme.colors.white)};
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  transition: border 300ms ease;

  ${theme.typography.h5.mobile}
  height: 40px;

  @media ${theme.breakpoints.atleast.md} {
    ${theme.typography.h5.desktop}
    height: 48px;
  }

  &:hover {
    border: 1px solid ${({ $mode }) => ($mode === 'light' ? theme.colors.charcoal : theme.colors.white)};
  }

  &:focus-within {
    border: 1px solid ${({ $mode }) => ($mode === 'light' ? theme.colors.blue : theme.colors.white)};
    box-shadow: 0px 0px 4px ${({ $mode }) => ($mode === 'light' ? 'rgba(0, 94, 235, 0.5)' : theme.colors.white)};
  }
`

const SelectElement = styled.select`
  appearance: none;
  border: none;
  outline: none;
  background: none;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin: 0px;
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 14px;
`

const SelectedValue = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1;

  ${theme.typography.h5.mobile}
  padding: 0px 12px;

  @media ${theme.breakpoints.atleast.md} {
    ${theme.typography.h5.desktop}
  }
`

const Arrow = styled.div`
  margin-right: 8px;
  height: 16px;
  width: 16px;
`
