import React from 'react'
import ReactMarkdown from 'react-markdown'
import type { TextColor } from 'styles/types'
import styled from 'styled-components'

type TextAlign = 'left' | 'right' | 'center' | 'justify'

type Props = {
  children: React.ReactNode
  color?: TextColor
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  mega?: boolean
  withMarkdown?: boolean
  align?: TextAlign
  onClick?: () => void
}

const Heading: React.FC<Props> = (props) => {
  const { children, color, type = 'h1', mega = false, withMarkdown = false, align, onClick } = props

  const classNames = ['heading']

  if (mega) classNames.push('text-mega')
  if (color) classNames.push(`color-${color}`)

  return (
    <HeadingElement as={type} className={classNames.join(' ')} $align={align} onClick={onClick}>
      {withMarkdown && (
        <ReactMarkdown allowedElements={['strong']} unwrapDisallowed>
          {children as string}
        </ReactMarkdown>
      )}
      {!withMarkdown && children}
    </HeadingElement>
  )
}

export default Heading

const HeadingElement = styled.h1<{ $align?: TextAlign }>`
  text-align: ${({ $align }) => $align};
`
