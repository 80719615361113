import React, { useState } from 'react'

import Button from 'components/Button'
import Flex from 'components/Flex'
import GoogleAdsConversion from 'layout/scripts/GoogleAdsConversion'
import Heading from 'components/Heading'
import Illustration from 'components/Illustration'
import LeadCaptureForm from 'forms/LeadCaptureForm'
import Modal from 'layout/modals/Modal'
import Spacer from 'components/Spacer'
import Text from 'components/Text'
import styled from 'styled-components'
import theme from 'styles/theme'

type Props = {
  onClose: () => void
}

export default function LegalCaptureModal(props: Props) {
  const { onClose } = props

  const [showSuccess, setSuccess] = useState(false)

  return (
    <Modal onClose={() => onClose()}>
      {!showSuccess && (
        <InnerWrapper>
          <Flex column center>
            <Illustration name='132x70:trial' />

            <Spacer size={{ default: 8, md: 16 }} />

            <Heading type='h3' align='center'>
              Start your free trial
            </Heading>

            <Spacer size={{ default: 16, md: 24 }} />

            <Text type='body-small' align='center'>
              {`Fill out your details below and we'll be in touch`}
            </Text>

            <Spacer size={{ default: 32, md: 48 }} />
          </Flex>

          <FormWrapper>
            <LeadCaptureForm onSuccess={() => setSuccess(true)} submitText='Request Trial' />
          </FormWrapper>
        </InnerWrapper>
      )}

      {showSuccess && (
        <SuccessWrapper>
          <Illustration name='132x70:success' />

          <Spacer size={{ default: 8, md: 16 }} />

          <Heading type='h3'>All done!</Heading>

          <Spacer size={{ default: 16, md: 24 }} />

          <Text type='body-small' align='center'>
            Thank you for your enquiry. A member of <br />
            the team will be in touch shortly!
          </Text>

          <Spacer size={{ default: 40 }} />

          <Button block onClick={() => onClose()}>
            Finish
          </Button>

          <GoogleAdsConversion type='free-trial-request' />
        </SuccessWrapper>
      )}
    </Modal>
  )
}

const InnerWrapper = styled.div`
  padding-bottom: 80px;
  @media ${theme.breakpoints.atleast.md} {
    padding-bottom: 0px;
  }
`

const FormWrapper = styled.div`
  max-width: 440px;
  margin: auto;
`

const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 280px;
  margin: auto;
  @media ${theme.breakpoints.atleast.md} {
    max-width: 100%;
  }
`
