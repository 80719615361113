import DesktopNavItem from './DesktopNavItem'
import { HeaderMenu } from 'content/getHeaderMenu'
import { Mode } from 'styles/types'
import React from 'react'
import styled from 'styled-components'

type Props = {
  headerMenu: HeaderMenu
  mode: Mode
}

export default function DesktopNav (props: Props) {

  const {
    headerMenu,
    mode,
  } = props

  return (
    <Container>
      {headerMenu.map((headerMenuOption) => (
        <DesktopNavItem key={headerMenuOption.title} mode={mode} {...headerMenuOption} />
      ))}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 8px;
`
