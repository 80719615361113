import React from 'react'
import styled from 'styled-components'
import theme from 'styles/theme'

export type Props = {
  className?: string
  value?: boolean
  onChange?: (e: React.MouseEvent<HTMLElement>) => void
  locked?: boolean
  // lockedReason?: string // @TODO Will add this once tooltip component is done
  disabled?: boolean
  allowPropagation?: boolean
}

const Toggle = ({ className, value = false, onChange, locked = false, disabled, allowPropagation }: Props) => {
  const toggleState = value ? 'active' : 'inactive'
  const icon = locked ? 'locked' : value ? 'on' : 'off'
  const iconColor = states[toggleState].color as keyof typeof theme.colors

  return (
    <Container className={className} $disabled={disabled}>
      <ToggleContainer
        $state={toggleState}
        $on={value}
        onClick={(e) => {
          !allowPropagation && e.stopPropagation()
          !locked && onChange?.(e)
        }}
        $disabled={disabled}
      >
        <ToggleSwitch $on={value}>
          {!disabled && (
            <Label $on={value}>
              <Icon name={icon} color={iconColor} />
            </Label>
          )}
        </ToggleSwitch>
      </ToggleContainer>
    </Container>
  )
}

export default Toggle

const states = {
  inactive: {
    bg: theme.colors.charcoal20,
    bgHover: theme.colors.charcoal40,
    bgClick: theme.colors.charcoal40,
    bgDisabled: theme.colors.charcoal10,
    color: 'charcoal40',
  },
  active: {
    bg: theme.colors.blue,
    bgHover: theme.colors.blueHover,
    bgClick: theme.colors.blueHover,
    bgDisabled: theme.colors.charcoal10,
    color: 'blue',
  },
}

type StateProps = 'active' | 'inactive'

const Container = styled.div<{ $disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  pointer-events: ${({ $disabled }) => $disabled && 'none'};
  user-select: ${({ $disabled }) => $disabled && 'none'};
`

const ToggleContainer = styled.button<{ $on: boolean; $state: StateProps; $disabled?: boolean }>`
  all: unset;
  width: 60px;
  height: 32px;
  border-radius: 70px;
  position: relative;
  cursor: pointer;
  background: ${({ $state, $disabled }) => states[$state][$disabled ? 'bgDisabled' : 'bg']};
  transition: background-color 300ms ease;

  &:hover {
    background: ${({ $state }) => states[$state].bgHover};
  }

  &:active {
    background: ${({ $state }) => states[$state].bgClick};
  }
`

const ToggleSwitch = styled.div<{ $on: boolean }>`
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.blue};
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 2px;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: ${({ $on }) => ($on ? 'clickToggleOff' : 'clickToggleOn')};

  @keyframes clickToggleOn {
    from {
      left: 30px;
    }
    to {
      left: 2px;
    }
  }
  @keyframes clickToggleOff {
    from {
      left: 2px;
    }
    to {
      left: 30px;
    }
  }
`

const Label = styled.div<{ $on: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

type IconProps = {
  name: 'on' | 'off' | 'locked'
  color: keyof typeof theme.colors
}

const Icon = ({ name, color }: IconProps) => {
  if (name === 'locked') {
    return (
      <svg width='12' height='14' viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M3.66667 3.5C3.66667 2.21134 4.71134 1.16667 6 1.16667C7.28866 1.16667 8.33333 2.21134 8.33333 3.5V4.66659H3.66667V3.5ZM2.5 4.66659V3.5C2.5 1.567 4.067 0 6 0C7.933 0 9.5 1.567 9.5 3.5V4.66659H10.25C10.8023 4.66659 11.25 5.1143 11.25 5.66659V12.9999C11.25 13.5522 10.8023 13.9999 10.25 13.9999H1.75C1.19772 13.9999 0.75 13.5522 0.75 12.9999V5.66659C0.75 5.1143 1.19772 4.66659 1.75 4.66659H2.5Z'
          fill={theme.colors[color]}
        />
      </svg>
    )
  }

  if (name === 'off') {
    return (
      <svg width='20' height='8' viewBox='0 0 20 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M6.94904 3.98674C6.94904 4.71149 6.81647 5.3346 6.55131 5.85606C6.28616 6.37532 5.92268 6.77525 5.46088 7.05587C5.00128 7.33428 4.47871 7.47348 3.89317 7.47348C3.30542 7.47348 2.78064 7.33428 2.31883 7.05587C1.85924 6.77525 1.49686 6.37421 1.23171 5.85275C0.96656 5.33128 0.833984 4.70928 0.833984 3.98674C0.833984 3.26199 0.96656 2.63999 1.23171 2.12074C1.49686 1.59927 1.85924 1.19934 2.31883 0.920928C2.78064 0.640309 3.30542 0.5 3.89317 0.5C4.47871 0.5 5.00128 0.640309 5.46088 0.920928C5.92268 1.19934 6.28616 1.59927 6.55131 2.12074C6.81647 2.63999 6.94904 3.26199 6.94904 3.98674ZM5.93484 3.98674C5.93484 3.43434 5.84535 2.96922 5.66637 2.59138C5.4896 2.21133 5.24655 1.92408 4.9372 1.72964C4.63007 1.53299 4.28206 1.43466 3.89317 1.43466C3.50207 1.43466 3.15296 1.53299 2.84582 1.72964C2.53869 1.92408 2.29563 2.21133 2.11665 2.59138C1.93989 2.96922 1.8515 3.43434 1.8515 3.98674C1.8515 4.53914 1.93989 5.00537 2.11665 5.38542C2.29563 5.76326 2.53869 6.05051 2.84582 6.24716C3.15296 6.4416 3.50207 6.53883 3.89317 6.53883C4.28206 6.53883 4.63007 6.4416 4.9372 6.24716C5.24655 6.05051 5.4896 5.76326 5.66637 5.38542C5.84535 5.00537 5.93484 4.53914 5.93484 3.98674Z'
          fill={theme.colors[color]}
          fillOpacity='0.6'
        />
        <path
          d='M8.22591 7.38068V0.592803H12.4352V1.47443H9.25006V3.54261H12.1336V4.42093H9.25006V7.38068H8.22591Z'
          fill={theme.colors[color]}
          fillOpacity='0.6'
        />
        <path
          d='M13.6947 7.38068V0.592803H17.9039V1.47443H14.7188V3.54261H17.6023V4.42093H14.7188V7.38068H13.6947Z'
          fill={theme.colors[color]}
          fillOpacity='0.6'
        />
      </svg>
    )
  }

  if (name === 'on') {
    return (
      <svg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M6.11506 3.98674C6.11506 4.71149 5.98248 5.3346 5.71733 5.85606C5.45218 6.37532 5.0887 6.77525 4.62689 7.05587C4.1673 7.33428 3.64473 7.47348 3.05919 7.47348C2.47143 7.47348 1.94665 7.33428 1.48485 7.05587C1.02525 6.77525 0.662879 6.37421 0.397727 5.85275C0.132576 5.33128 0 4.70928 0 3.98674C0 3.26199 0.132576 2.63999 0.397727 2.12074C0.662879 1.59927 1.02525 1.19934 1.48485 0.920928C1.94665 0.640309 2.47143 0.5 3.05919 0.5C3.64473 0.5 4.1673 0.640309 4.62689 0.920928C5.0887 1.19934 5.45218 1.59927 5.71733 2.12074C5.98248 2.63999 6.11506 3.26199 6.11506 3.98674ZM5.10085 3.98674C5.10085 3.43434 5.01136 2.96922 4.83239 2.59138C4.65562 2.21133 4.41256 1.92408 4.10322 1.72964C3.79609 1.53299 3.44807 1.43466 3.05919 1.43466C2.66809 1.43466 2.31897 1.53299 2.01184 1.72964C1.7047 1.92408 1.46165 2.21133 1.28267 2.59138C1.1059 2.96922 1.01752 3.43434 1.01752 3.98674C1.01752 4.53914 1.1059 5.00537 1.28267 5.38542C1.46165 5.76326 1.7047 6.05051 2.01184 6.24716C2.31897 6.4416 2.66809 6.53883 3.05919 6.53883C3.44807 6.53883 3.79609 6.4416 4.10322 6.24716C4.41256 6.05051 4.65562 5.76326 4.83239 5.38542C5.01136 5.00537 5.10085 4.53914 5.10085 3.98674Z'
          fill={theme.colors[color]}
        />
        <path
          d='M12.8706 0.592803V7.38068H11.9293L8.47905 2.40246H8.41607V7.38068H7.39193V0.592803H8.33984L11.7934 5.57765H11.8564V0.592803H12.8706Z'
          fill={theme.colors[color]}
        />
      </svg>
    )
  }

  return null
}
