import React, { ReactNode } from 'react'

import Text from 'components/Text'
import styled from 'styled-components'

type Props = {
  children: ReactNode
  label?: string
  error?: string
}

const FieldWrapper = (props: Props) => {
  const { children, label, error } = props

  return (
    <Container>
      <Text type='h6' color='charcoal60'>
        {label}
      </Text>

      <Children>{children}</Children>

      {error && (
        <Text type='h6' color='errorDark' align='right'>
          {error}
        </Text>
      )}
    </Container>
  )
}

export default FieldWrapper

const Children = styled.div``

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
`
