import Button from 'components/Button'
import { CookieSettings } from 'layout/Layout'
import Heading from 'components/Heading'
import Modal from 'layout/modals/Modal'
import Text from 'components/Text'
import Toggle from 'components/Toggle'
import styled from 'styled-components'
import theme from 'styles/theme'
import { useState } from 'react'

type Props = {
  cookieSettings: CookieSettings | null
  onClose: () => void
  onSave: (cookieSettings: CookieSettings) => void
}

export default function ManageCookiesModal(props: Props) {
  const { cookieSettings, onClose, onSave } = props

  const [allowFunctionalCookies, setAllowFunctionalCookies] = useState(
    cookieSettings ? cookieSettings.functional : true,
  )
  const [allowAnalyticsCookies, setAllowAnalyticsCookies] = useState(cookieSettings ? cookieSettings.analytics : true)
  const [allowMarketingCookies, setAllowMarketingCookies] = useState(cookieSettings ? cookieSettings.marketing : true)

  const handleSave = () => {
    const cookieSettings = {
      functional: allowFunctionalCookies,
      analytics: allowAnalyticsCookies,
      marketing: allowMarketingCookies,
    }
    onSave(cookieSettings)
    onClose()
  }

  return (
    <Modal onClose={() => onClose()}>
      <Header>
        <Heading type='h3'>Manage Cookies</Heading>
      </Header>
      <ToggleSections>
        <ToggleSection>
          <Texts>
            <Heading type='h5'>Strictly Necessary</Heading>
            <Text type='body-small' color='charcoal60'>
              Essential for the site to function. Always On.
            </Text>
          </Texts>
          <Toggle value={true} locked />
        </ToggleSection>
        <ToggleSection>
          <Texts>
            <Heading type='h5'>Functional</Heading>
            <Text type='body-small' color='charcoal60'>
              Used to remember preference selections and provide enhanced features.
            </Text>
          </Texts>
          <Toggle value={allowFunctionalCookies} onChange={() => setAllowFunctionalCookies(!allowFunctionalCookies)} />
        </ToggleSection>
        <ToggleSection>
          <Texts>
            <Heading type='h5'>Analytics</Heading>
            <Text type='body-small' color='charcoal60'>
              Used to measure usage and improve your experience.
            </Text>
          </Texts>
          <Toggle value={allowAnalyticsCookies} onChange={() => setAllowAnalyticsCookies(!allowAnalyticsCookies)} />
        </ToggleSection>
        <ToggleSection>
          <Texts>
            <Heading type='h5'>Marketing</Heading>
            <Text type='body-small' color='charcoal60'>
              Used for targeted advertising.
            </Text>
          </Texts>
          <Toggle value={allowMarketingCookies} onChange={() => setAllowMarketingCookies(!allowMarketingCookies)} />
        </ToggleSection>
      </ToggleSections>
      <Button block onClick={() => handleSave()}>
        Accept Selected
      </Button>
    </Modal>
  )
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 16px 0 24px 0;

  img {
    margin: 0 0 8px 0;
    height: 48px;
  }

  h3 {
    margin: 0 !important;
  }

  @media ${theme.breakpoints.atleast.lg} {
    margin: 0 0 48px 0;

    img {
      margin: 0 0 16px 0;
      height: 70px;
    }
  }
`

const ToggleSections = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`

const ToggleSection = styled.div`
  display: flex;
  padding-top: 24px;
  padding-bottom: 24px;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.charcoal10};

  &:last-of-type {
    border-bottom: none;
  }
`

const Texts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
