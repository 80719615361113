import Icon from './Icon'
import React from 'react'
import styled from 'styled-components'
import theme from 'styles/theme'

type Props = {
  href?: string
  onClick?: () => void
  arrow?: boolean
  block?: boolean
  color?: 'blue' | 'aquamarine'
  size?: 'small' | 'large'
  loading?: boolean
  children?: React.ReactNode
  className?: string
}

export default function Button(props: Props) {
  const {
    href,
    onClick,
    arrow = false,
    block = false,
    color = 'blue',
    size = 'large',
    loading = false,
    children,
    className,
  } = props

  return (
    <ButtonElement
      as={href ? 'a' : undefined}
      href={href || undefined}
      onClick={() => !loading && onClick?.()}
      className={`button-color-${color} ${className}`}
      $block={block}
      $size={size}
    >
      {children}

      {arrow && <Icon scheme={color === 'aquamarine' ? 'dark-neon' : 'dark-blue'} icon='arrow' direction='right' />}

      {loading && (
        <LoadingWrapper>
          <LoadingIcon viewBox='0 0 1024 1024' focusable='false' width='20px' height='20px' fill='white'>
            <path d='M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z'></path>
          </LoadingIcon>
        </LoadingWrapper>
      )}
    </ButtonElement>
  )
}

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  cursor: default;
`

const ButtonElement = styled.div<{ $block?: boolean; $size: 'small' | 'large' }>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  border: none;
  height: fit-content;
  white-space: nowrap;
  width: ${({ $block }) => ($block ? '100%' : 'fit-content')};
  user-select: none;
  overflow: hidden;

  transition: background-color 300ms ease;

  &.button-color-blue {
    background-color: ${theme.colors.blue};
    color: ${theme.colors.white};

    ${LoadingWrapper} {
      background-color: ${theme.colors.blue};
    }
  }

  &.button-color-blue:hover {
    background-color: ${theme.colors.blueHover};
  }

  &.button-color-aquamarine {
    background-color: ${theme.colors.aquamarine};
    color: ${theme.colors.navy};

    ${LoadingWrapper} {
      background-color: ${theme.colors.aquamarine};
    }
  }

  ${theme.typography.h5.mobile}
  border-radius: 10px;
  height: ${({ $size }) => ($size === 'large' ? 40 : 32)}px;
  padding: 0px 12px;

  @media ${theme.breakpoints.atleast.md} {
    ${theme.typography.h5.desktop}
    border-radius: 12px;
    height: 48px;
    height: ${({ $size }) => ($size === 'large' ? 48 : 40)}px;
    padding: 0px 16px;
  }

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`

const LoadingIcon = styled.svg`
  animation: rotate 0.8s infinite linear;
  position: absolute;
  left: calc(50% - 10px);
  top: calc(50% - 10px);

  path {
    stroke: white;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`
