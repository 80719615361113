import React from 'react'
import styled from 'styled-components'
import theme from 'styles/theme'

const illustrations = [
  '132x70:buildings',
  '132x70:collaboration',
  '132x70:documents',
  '132x70:login',
  '132x70:monitoring',
  '132x70:search',
  '132x70:success',
  '132x70:trial',

  '72x72:chat',
  '72x72:cogs',
] as const

export type IllustrationName = typeof illustrations[number]

export type Props = {
  className?: string
  name: IllustrationName
}

const Illustration = ({ className, name }: Props) => {
  const type = name.split(':')[0] as '132x70' | '72x72'
  const file = name.split(':')[1]

  const ImageComponent = imageComponents[type]

  return <ImageComponent className={className} alt='' src={`/assets/illustrations/${type}/${file}.png`} />
}

export default Illustration

const ImageType1 = styled.img`
  width: 90px;
  height: 48px;
  @media ${theme.breakpoints.atleast.md} {
    width: 132px;
    height: 70px;
  }
`

const ImageType2 = styled.img`
  width: 56px;
  height: 56px;
  @media ${theme.breakpoints.atleast.md} {
    width: 72px;
    height: 72px;
  }
`

const imageComponents = {
  '132x70': ImageType1,
  '72x72': ImageType2,
}
