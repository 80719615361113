import { HeaderMenuOption } from 'content/getHeaderMenu'
import Heading from 'components/Heading'
import Icon from 'components/Icon'
import Image from 'next/image'
import React from 'react'
import Tag from 'components/Tag'
import illustrationChat from 'public/assets/illustrations/60x50/chat.png'
import illustrationRoadmap from 'public/assets/illustrations/60x50/roadmap.png'
import styled from 'styled-components'
import theme from 'styles/theme'

const illustrations = {
  chat: illustrationChat,
  roadmap: illustrationRoadmap,
}

type Props = HeaderMenuOption & {
  closeMobileNav: () => void
}

export default function MobileNavItem(props: Props) {
  const { title, href, submenu, submenuFooter, closeMobileNav } = props

  const [expanded, setExpanded] = React.useState(false)

  return (
    <Container key={title} $expanded={expanded}>
      <PrimaryButton href={href} onClick={submenu ? () => setExpanded(!expanded) : undefined}>
        {title}
        {submenu && <Icon scheme='white' icon='arrow' direction={expanded ? 'up' : 'down'} />}
      </PrimaryButton>

      {submenu && expanded && (
        <Dropdown>
          <DropdownItems>
            {submenu.map((submenuOption) => (
              <DropdownItem key={submenuOption.title} href={submenuOption.href} onClick={() => closeMobileNav()}>
                {submenuOption.icon && <Icon icon='doubleBox' scheme='white' />}
                <DropdownItemDivider>
                  <DropdownItemTextWrapper>
                    <DropdownItemTitleWrapper>
                      <DropdownItemTitle>{submenuOption.title}</DropdownItemTitle>
                      {submenuOption.tag && <Tag text={submenuOption.tag} type='orange' />}
                    </DropdownItemTitleWrapper>
                  </DropdownItemTextWrapper>
                  <DropdownItemArrowWrapper>
                    <Icon scheme='white' icon='arrow' direction='right' />
                  </DropdownItemArrowWrapper>
                </DropdownItemDivider>
              </DropdownItem>
            ))}
          </DropdownItems>

          {submenuFooter && (
            <DropdownFooter>
              <Image src={illustrations[submenuFooter.illustration]} alt='' width={60} height={50} />
              <DropdownFooterTextWrapper>
                <Heading type='h5'>{submenuFooter.text}</Heading>
                <DropdownFooterLink href={submenuFooter.linkHref}>
                  {submenuFooter.linkText} <Icon scheme='white' icon='arrow' direction='right' />
                </DropdownFooterLink>
              </DropdownFooterTextWrapper>
            </DropdownFooter>
          )}
        </Dropdown>
      )}
    </Container>
  )
}

const Container = styled.div<{ $expanded: boolean }>`
  position: relative;
  user-select: none;
  border-bottom: 1px solid ${theme.colors.charcoal10};
`

const PrimaryButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0px;
  ${theme.typography.h4.mobile}
  color: ${theme.colors.charcoal};
  cursor: pointer;
  transition: opacity 0.25s ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  @media (hover: hover) {
    &:hover {
      opacity: 0.5;
    }
  }
  @media (hover: none) {
    &:active {
      opacity: 0.5;
    }
  }
`

const Dropdown = styled.div`
  flex-direction: column;
`

const DropdownItems = styled.div``

const DropdownItemTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const DropdownItemTitleWrapper = styled.div`
  display: flex;
  gap: 4px;
`

const DropdownItemTitle = styled.div`
  ${theme.typography.h5.mobile}
  color: ${theme.colors.charcoal};
  white-space: nowrap;
`

const DropdownItemArrowWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  min-width: 40px;
`

const DropdownItem = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${theme.colors.charcoal};
  cursor: pointer;
  transition: opacity 0.25s ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  @media (hover: hover) {
    &:hover {
      opacity: 0.6;
    }
  }
  @media (hover: none) {
    &:active {
      opacity: 0.5;
    }
  }
`

const DropdownItemDivider = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 0px;
  border-bottom: 1px solid ${theme.colors.charcoal10};
`

const DropdownFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 0px;
  cursor: default;
`

const DropdownFooterTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const DropdownFooterLink = styled.a`
  display: flex;
  align-items: center;
  ${theme.typography.h5.mobile}
  color: ${theme.colors.blue};
  &:hover {
    color: ${theme.colors.blueHover};
    cursor: pointer;
  }
`
