import Button from 'components/Button'
import Centraliser from 'components/Centraliser'
import { HeaderMenu } from 'content/getHeaderMenu'
import Heading from 'components/Heading'
import Image from 'next/image'
import MobileNavItem from './MobileNavItem'
import React from 'react'
import Spacer from 'components/Spacer'
import illustration from 'public/assets/illustrations/90x90/rapid.png'
import styled from 'styled-components'
import theme from 'styles/theme'
import { useGlobalContexts } from 'layout/GlobalContextsProvider'

type Props = {
  headerMenu: HeaderMenu
  closeMobileNav: () => void
}

export default function MobileNav(props: Props) {
  const { headerMenu, closeMobileNav } = props

  const { setShowLeadCaptureModal } = useGlobalContexts()

  return (
    <Container>
      <Centraliser>
        <NavItems>
          {headerMenu.map((headerMenuOption) => (
            <MobileNavItem key={headerMenuOption.title} closeMobileNav={closeMobileNav} {...headerMenuOption} />
          ))}
        </NavItems>

        <Spacer size={32} />

        <Banner>
          <Texts>
            <Heading type='h3'>Ready to make traceability a breeze?</Heading>
            <Spacer size={16} />
            <Button
              arrow={true}
              onClick={() => {
                setShowLeadCaptureModal(true)
                closeMobileNav()
              }}
            >
              Start building for free
            </Button>
          </Texts>
          <ImageWrapper>
            <Image src={illustration} width={50} height={50} alt='' />
          </ImageWrapper>
        </Banner>
      </Centraliser>
    </Container>
  )
}

const Container = styled.div`
  overflow: auto;
  position: fixed;
  top: 56px;
  left: 0px;
  width: 100%;
  height: calc(100vh - 56px);
  background: ${theme.colors.white};
  padding: 32px 0px;

  @media ${theme.breakpoints.atleast.md} {
    top: 64px;
    height: calc(100vh - 64px);
  }
`

const NavItems = styled.div``

const Banner = styled.div`
  display: flex;
  padding: 24px;
  gap: 16px;
  background: ${theme.colors.paleBlue};
  border-radius: 16px;

  @media ${theme.breakpoints.atleast.md} {
    padding: 32px;
  }
`

const Texts = styled.div`
  flex: 1;
`

const ImageWrapper = styled.div`
  position: relative;
  top: -12px;
`
