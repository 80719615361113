import { ColorScheme } from 'styles/types'
import Image from 'next/image'
import React from 'react'
import arrowDarkBlue from 'public/assets/images/arrow-down-dark-blue.svg'
import arrowDarkNeon from 'public/assets/images/arrow-down-dark-neon.svg'
import arrowWhite from 'public/assets/images/arrow-down-white.svg'
import bulbDarkBlue from 'public/assets/images/bulb-dark-blue.svg'
import bulbDarkNeon from 'public/assets/images/bulb-dark-neon.svg'
import bulbWhite from 'public/assets/images/bulb-white.svg'
import burgerDarkBlue from 'public/assets/images/burger-icon-dark-blue.svg'
import burgerWhite from 'public/assets/images/burger-icon-white.svg'
import chatDarkBlue from 'public/assets/images/chat-dark-blue.svg'
import chatDarkNeon from 'public/assets/images/chat-dark-neon.svg'
import chatWhite from 'public/assets/images/chat-white.svg'
import circleCheckDarkBlue from 'public/assets/images/circle-check-dark-blue.svg'
import circleCheckLight from 'public/assets/images/circle-check-light.svg'
import doubleBoxDarkBlue from 'public/assets/images/double-box-dark-blue.svg'
import doubleBoxDarkNeon from 'public/assets/images/double-box-dark-neon.svg'
import doubleBoxWhite from 'public/assets/images/double-box-white.svg'
import freetrialWhite from 'public/assets/images/freetrial-white.svg'
import realtimeDarkBlue from 'public/assets/images/realtime-dark-blue.svg'
import realtimeDarkNeon from 'public/assets/images/realtime-dark-neon.svg'
import realtimeWhite from 'public/assets/images/realtime-white.svg'
import successWhite from 'public/assets/images/success-white.svg'
import threeDotsWhite from 'public/assets/images/three-dots-white.svg'
import triplePlayDarkBlue from 'public/assets/images/triple-play-dark-blue.svg'
import triplePlayDarkNeon from 'public/assets/images/triple-play-dark-neon.svg'
import triplePlayWhite from 'public/assets/images/triple-play-white.svg'
import xMarkDarkBlue from 'public/assets/images/x-mark-dark-blue.svg'
import xMarkWhite from 'public/assets/images/x-mark-white.svg'

type Props = {
  scheme: ColorScheme
  icon: keyof typeof Icons
  direction?: 'down' | 'up' | 'left' | 'right'
}

const _direction = {
  left: { transform: 'rotate(90deg)' },
  up: { transform: 'rotate(180deg)' },
  right: { transform: 'rotate(270deg)' },
  down: { transform: 'rotate(0)' },
}

const defaults = { white: null, light: null, 'dark-blue': null, 'dark-neon': null }

const Icons = {
  doubleBox: {
    ...defaults,
    white: doubleBoxWhite,
    'dark-blue': doubleBoxDarkBlue,
    'dark-neon': doubleBoxDarkNeon,
  },
  arrow: {
    ...defaults,
    white: arrowWhite,
    'dark-blue': arrowDarkBlue,
    'dark-neon': arrowDarkNeon,
  },
  burger: {
    ...defaults,
    white: burgerWhite,
    'dark-blue': burgerDarkBlue,
  },
  circleCheck: {
    ...defaults,
    light: circleCheckLight,
    'dark-blue': circleCheckDarkBlue,
  },
  xMark: {
    ...defaults,
    white: xMarkWhite,
    'dark-blue': xMarkDarkBlue,
  },
  bulb: {
    ...defaults,
    white: bulbWhite,
    'dark-blue': bulbDarkBlue,
    'dark-neon': bulbDarkNeon,
  },
  chat: {
    ...defaults,
    white: chatWhite,
    'dark-blue': chatDarkBlue,
    'dark-neon': chatDarkNeon,
  },
  triplePlay: {
    ...defaults,
    white: triplePlayWhite,
    'dark-blue': triplePlayDarkBlue,
    'dark-neon': triplePlayDarkNeon,
  },
  realtime: {
    ...defaults,
    white: realtimeWhite,
    'dark-blue': realtimeDarkBlue,
    'dark-neon': realtimeDarkNeon,
  },
  success: {
    ...defaults,
    white: successWhite,
  },
  freetrial: {
    ...defaults,
    white: freetrialWhite,
  },
  threeDots: {
    ...defaults,
    white: threeDotsWhite,
  },
}

const Icon: React.FC<Props> = (props) => {
  const { scheme, icon, direction = 'down' } = props

  const src = Icons[icon][scheme]

  return src ? <Image src={src} alt='Onetrace' style={_direction[direction]} /> : null
}

export default Icon
