import Button from 'components/Button'
import type { ColorScheme } from 'styles/types'
import React from 'react'
import Text from 'components/Text'
import styled from 'styled-components'
import theme from 'styles/theme'
import { useGlobalContexts } from 'layout/GlobalContextsProvider'

type Props = {
  scheme?: ColorScheme
  onAccept?: () => void
}
const CookieNotice = (props: Props) => {
  const { scheme = 'white', onAccept } = props

  const { setShowManageCookiesModal } = useGlobalContexts()

  return (
    <Container className={scheme}>
      <Panel>
        <NoticeText>
          <NoticeTextMaxWidth>
            <Text type='body-small' align='center'>
              <Text type='body-small-pullout' wrapper='span' align='center'>
                We use cookies
              </Text>{' '}
              to make our site work and also for analytics and advertising purposes. You can enable or disable optional
              cookies as desired. See our Privacy Policy for more details.
            </Text>
          </NoticeTextMaxWidth>
        </NoticeText>

        <Actions>
          <AcceptButton size='small' onClick={() => onAccept?.()}>
            Accept & Close
          </AcceptButton>

          <MoreOptionsLink onClick={() => setShowManageCookiesModal(true)}>
            Manage Cookies
            <svg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M1.08594 11L6.08594 6L1.08594 1' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
          </MoreOptionsLink>
        </Actions>
      </Panel>
    </Container>
  )
}

export default CookieNotice

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.charcoal60};
  z-index: 999;
`

const Panel = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  background: ${theme.colors.white};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  padding: 24px;
  width: 100%;
`

const NoticeText = styled.div``

const NoticeTextMaxWidth = styled.div`
  max-width: 720px;
`

const MoreOptionsLink = styled.div`
  ${theme.typography.h5.mobile};
  color: ${theme.colors.charcoal60};
  transition: color 300ms ease;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;

  svg path {
    stroke: ${theme.colors.charcoal60};
    transition: stroke 300ms ease;
  }

  &:hover,
  &:hover svg path {
    color: ${theme.colors.charcoal};
    stroke: ${theme.colors.charcoal};
  }

  @media ${theme.breakpoints.atleast.md} {
    ${theme.typography.h5.desktop};
  }
`

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`

const AcceptButton = styled(Button)``
