import styled, { css } from 'styled-components'

import Block from 'components/Block'
import Button from 'components/Button'
import Centraliser from 'components/Centraliser'
import Heading from 'components/Heading'
import Spacer from 'components/Spacer'
import Text from 'components/Text'
import { useGlobalContexts } from 'layout/GlobalContextsProvider'

const Footer = () => {
  const { setShowLeadCaptureModal, footerMenu } = useGlobalContexts()

  return (
    <Block scheme='dark-blue' style={{ overflow: 'hidden' }}>
      <Spacer size={{ default: 60, md: 80 }} />

      <Centraliser>
        <PrimaryContent>
          <CTAWrapper>
            <CTAContent>
              <Heading type='h3'>Ready to make traceability a breeze?</Heading>

              <Button onClick={() => setShowLeadCaptureModal(true)}>Start building for free</Button>
            </CTAContent>
          </CTAWrapper>

          <FooterMenu>
            {footerMenu.map((footerMenuGroup, groupIndex) => (
              <FooterMenuGroup key={groupIndex}>
                <Text type='body-large-pullout'>{footerMenuGroup.title}</Text>

                <FooterLinks>
                  {footerMenuGroup.options.map((option, optionIndex) => (
                    <FooterLink key={`${groupIndex}-${optionIndex}`} href={option.href}>
                      {option.text}
                    </FooterLink>
                  ))}
                </FooterLinks>
              </FooterMenuGroup>
            ))}
          </FooterMenu>
        </PrimaryContent>

        <SecondaryContent>
          <Text type='h6' color='white60'>
            &copy; Onetrace Ltd
          </Text>
        </SecondaryContent>
      </Centraliser>

      <Spacer size={{ default: 32, md: 40 }} />
    </Block>
  )
}

export default Footer

const PrimaryContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    border-top: 1px solid ${theme.colors.white10};
    padding-top: 64px;
    padding-bottom: 64px;
    gap: 64px;
    @media ${theme.breakpoints.atleast.lg} {
      /* flex-direction: row; @TODO put this back once we have more footer */
      padding-top: 120px;
      padding-bottom: 120px;
    }
  `}
`

const CTAWrapper = styled.div`
  flex: 1;
`

const CTAContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 24px;
    @media ${theme.breakpoints.atleast.lg} {
      /* max-width: 300px; @TODO put this back once we have more footer */
    }
  `}
`

const FooterMenu = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 32px;

  ${({ theme }) => css`
    @media ${theme.breakpoints.atleast.md} {
      flex-direction: row;
      gap: 48px;
    }
  `}
`

const FooterMenuGroup = styled.div`
  ${({ theme }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
    @media ${theme.breakpoints.atleast.lg} {
      gap: 16px;
    }
  `}
`

const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const FooterLink = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.white60} !important;
    &:hover {
      color: ${theme.colors.white} !important;
    }
    &:active {
      color: ${theme.colors.white90} !important;
    }

    ${theme.typography['body-small'].mobile}
    @media ${theme.breakpoints.atleast.lg} {
      ${theme.typography['body-small'].desktop}
    }

    transition: color 0.25s ease;
  `}
`

const SecondaryContent = styled.div``
