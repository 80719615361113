import { HeaderMenuOption } from 'content/getHeaderMenu'
import Heading from 'components/Heading'
import Icon from 'components/Icon'
import Image from 'next/image'
import { Mode } from 'styles/types'
import React from 'react'
import Tag from 'components/Tag'
import illustrationChat from 'public/assets/illustrations/60x50/chat.png'
import illustrationRoadmap from 'public/assets/illustrations/60x50/roadmap.png'
import styled from 'styled-components'
import theme from 'styles/theme'

const illustrations = {
  chat: illustrationChat,
  roadmap: illustrationRoadmap,
}

type Props = HeaderMenuOption & {
  mode: Mode
}

export default function DesktopNavItem(props: Props) {
  const { mode, title, href, submenu, submenuFooter } = props

  return (
    <Container key={title} $mode={mode}>
      <TabButton $mode={mode} href={href}>
        {title}
      </TabButton>

      {submenu && (
        <DropdownWrapper>
          <Dropdown $mode={mode}>
            <DropdownItems>
              {submenu.map((submenuOption) => (
                <DropdownItem key={submenuOption.title} href={submenuOption.href}>
                  {submenuOption.icon && <Icon icon='doubleBox' scheme='white' />}
                  <DropdownItemTextWrapper>
                    <DropdownItemTitleWrapper>
                      <DropdownItemTitle>{submenuOption.title}</DropdownItemTitle>
                      {submenuOption.tag && <Tag text={submenuOption.tag} type='orange' />}
                    </DropdownItemTitleWrapper>
                    {submenuOption.caption && (
                      <DropdownItemCaptionWrapper>
                        <DropdownItemCaption>{submenuOption.caption}</DropdownItemCaption>
                      </DropdownItemCaptionWrapper>
                    )}
                  </DropdownItemTextWrapper>
                  <DropdownItemArrowWrapper>
                    <Icon scheme='white' icon='arrow' direction='right' />
                  </DropdownItemArrowWrapper>
                </DropdownItem>
              ))}
            </DropdownItems>

            {submenuFooter && (
              <DropdownFooter>
                <Image src={illustrations[submenuFooter.illustration]} alt='' width={60} height={50} />
                <DropdownFooterTextWrapper>
                  <Heading type='h5'>{submenuFooter.text}</Heading>
                  <DropdownFooterLink href={submenuFooter.linkHref}>
                    {submenuFooter.linkText} <Icon scheme='white' icon='arrow' direction='right' />
                  </DropdownFooterLink>
                </DropdownFooterTextWrapper>
              </DropdownFooter>
            )}
          </Dropdown>
        </DropdownWrapper>
      )}
    </Container>
  )
}

const TabButton = styled.a<{ $mode: Mode }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0px 12px;
  border-radius: 10px;
  ${theme.typography.h5.desktop}
  transition: background-color 0.3s ease;
  color: ${({ $mode }) => ($mode === 'light' ? theme.colors.charcoal : theme.colors.white)};
  cursor: ${({ href }) => (href ? 'pointer' : 'default')};
`

const DropdownWrapper = styled.div`
  position: absolute;
  top: 38px;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  height: fit-content;
  padding-top: 20px;
  z-index: 10;
`

const Dropdown = styled.div<{ $mode: Mode }>`
  flex-direction: column;
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.charcoal10};
  box-shadow: ${({ $mode }) => theme.shadows.dropdown[$mode]};
  border-radius: 32px;
  min-width: 360px;

  &::before {
    content: '';
    position: absolute;
    top: 12px;
    left: calc(50% - 9px);
    width: 18px;
    height: 15px;
    background: ${theme.colors.white};
    border-right: 1px solid ${theme.colors.charcoal10};
    border-bottom: 1px solid ${theme.colors.charcoal10};
    transform: matrix(-0.71, -0.6, 0.84, -0.71, 0, 0);
  }
`

const DropdownItems = styled.div`
  padding: 24px;
`

const DropdownItemTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 200px;
`

const DropdownItemTitleWrapper = styled.div`
  display: flex;
  gap: 4px;
`

const DropdownItemCaptionWrapper = styled.div``

const DropdownItemTitle = styled.div`
  ${theme.typography.h5.desktop}
  color: ${theme.colors.charcoal};
  white-space: nowrap;
`

const DropdownItemCaption = styled.div`
  ${theme.typography.h6.desktop}
  color: ${theme.colors.charcoal60};
  white-space: nowrap;
`

const DropdownItemArrowWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  visibility: hidden;
  min-width: 40px;
`

const DropdownItem = styled.a`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  border-radius: 12px;

  &:hover {
    background: ${theme.colors.paleBlue};
    ${DropdownItemArrowWrapper} {
      visibility: visible;
    }
    ${DropdownItemCaption} {
      color: ${theme.colors.charcoal};
    }
  }
`

const DropdownFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 24px;
  border-top: 1px solid ${theme.colors.charcoal5};
  cursor: default;
`

const DropdownFooterTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const DropdownFooterLink = styled.a`
  display: flex;
  align-items: center;
  ${theme.typography.h5.desktop}
  color: ${theme.colors.blue};
  &:hover {
    color: ${theme.colors.blueHover};
    cursor: pointer;
  }
`

const Container = styled.div<{ $mode: Mode }>`
  position: relative;
  user-select: none;

  ${DropdownWrapper} {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover {
    ${DropdownWrapper} {
      visibility: visible;
      opacity: 1;
    }
    ${TabButton} {
      background-color: ${({ $mode }) => ($mode === 'light' ? theme.colors.paleBlue : theme.colors.white10)};
    }
  }
`
